import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  lang: sessionStorage.getItem('lang') || 'zh',
  navIndex: sessionStorage.getItem('navIndex') || 0
}

const getters = {
  language: state => {
    return state.lang
  },
  navIndex: state => {
    return state.navIndex
  }
}

const mutations = {
  SET_LANGUAGE(state, lang) {
    state.lang = lang
    sessionStorage.setItem('lang', lang)
  },
  SET_NAVINDEX(state, navIndex) {
    state.navIndex = navIndex
    sessionStorage.setItem('navIndex', navIndex)
  },
}


export default new Vuex.Store({
  state,
  getters,
  mutations,
})
