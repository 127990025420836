export default {
  header: {
    nav1: 'Home',
    nav2: 'About',
    nav3: 'News',
    nav4: 'Technologies&Solutions',
    nav5: 'Contact Us'
  },
  home: {
    product: {
      title: 'Technologies Lead the Future',
      titleDetail:"Technologies create miracles, and make the future unlimited. We create the business modal innovation by synergizing the upstreams and downstreams in supply chain of photon counting CT.",
      titleDetail2:"We build the photon counting spectral imaging platform with photon counting spectral imaging technologies, multi-source imaging technologies, and artificial intelligence technologies.",
      product1: 'Instant whole body scan',
      product2: '4D dynamic imaging',
      product3: 'Crystal clear fine structure',
      product4: 'Ultra low dose',
      product5: 'Extremly high LCD',
      product6:"Multi-bin spectral imaging",
      product1Detail: 'Multi-source collaborative data acquisition accelerates the scanning several times, completing a whole-body CT scan within 3 seconds, making whole lung perfusion and even whole-body perfusion possible.',
      product2Detail: 'Multi-source collaborative data collection improves the time resolution several times, with 70ms single sector scanning and 17ms time resolution making 4D dynamic sequence imaging possible.',
      product3Detail: 'The channel density that is several times that of traditional detectors doubles the spatial resolution. The 30lp/cm spatial resolution allows sub-millimeter fine structures to be clearly visible.',
      product4Detail: 'Direct conversion photon counting detection technology, zero channel gap and direct conversion of X-ray photons significantly improve the geometric efficiency and conversion efficiency, and significantly reduce the radiation dose in CT exam.',
      product5Detail:"The increased weight of low-energy photons in the data significantly improves low-contrast detectablity. Low keV virtual monochromatic images can clearly display soft tissues.",
      product6Detail:"Supporting more than 4 energy bins, making multi contrast tracking possible, and multi energy bins improve accuracy of material decomposition."
    },
    contactUs: {
      title: 'Photon-counting by Your Side',
      textContent: 'Beijing Photon counting technology Ltd. established in june 2021, and started from July, 2022. Core members are from global leading companies in medical imaging, such as GE Healthcare, Philips, Canon, Siemens. Taking the opportunity of the 14th Five-Year Plan national key R&D supporting project of photon-counting CT, team is committed to achieving corner overtaking and technological leadership by technology innovation, creating medical imaging system solutions by commercial innovation. Provide technologically leading products and solutions to medical institutions and imaging equipment companies, and strive to become an industry innovation and technology leader.',
      btn: 'Learn More...'
    },
    news: {
      title: 'News',
      titleDetail:'In the 1980s, nuclear physics institutions represented by cern developed single-channel or linear array photon counting asics and detectors for high-energy particle spectroscopy analysis. In the 1990s, cdte/czt detectors became a hot topic at the time, But the crystal defects of czt materials were limited. In order to meet the application of large-size detectors, in 2000s, cern launched the medipix area array detector, which has 256x256 channels and can be spliced. GE healthcare, philips medical and siemens healthcarers all began to study the application of photon counting detector technology in CT in the 2000s, but it was not until around 2015 that siemens developed cadmium telluride photon counting detectors and GE healthcare developed deep silicon photon counting detector technology. Only then did breakthroughs begin.',
      title1: 'Oct, 2023',
      title2: 'May, 2021',
      title5: "April, 2023",
      detail1a1:'NAEOTOM Alpha photon counting CT system of Siemens healthcarers has got FDA approval, ',
      detail1: "FDA said this was the first major technological innovation for CT in more than 10 years, that had been approved by the FDA. In 2022, Siemens obtained the license for innovative product approval channels in China, and Siemens obtained the first NMPA registration certificate for photon counting on October 17, 2023.",
      detail1a2:'并于2023年10月17日西门子取得首个光子计数的NMPA注册证。',
      detail2: 'GE Healthcare announced Friday it is acquiring Swedish start-up Prismatic Sensors AB, bolstering its key medical imaging business. Prismatic Sensors AB was founded in 2012 as a spin-off from KTH Royal Institute of Technology in Stockholm, Sweden. It makes deep silicon detectors for CT imaging.',
      title3: '2020年11月 ',
      detail5: 'Canon Medical started clinical research of 1st Japanese produced PCCT system in National Cancer Center Japan. The NCC hospital East reported study results at the 82nd Congress of Japan Radiological Society and the 79th Congress of Japanese Society of Radiological technology held at Pacifico Yokohama from April 13 through April 16.'
    }
  },
  contactUs: {
    bannerText1: 'About Photon Counting',
    bannerText2: 'Contact Us',
    title1: 'Brand Story',
    title2: 'R&D Center',
    title3: 'Contact Us',
    contentTitle1: 'Beijing Photon Counting Technology Ltd.',
    contentTitle2: 'Beijing Photon Counting Medical Technology Ltd.',
    
    contentTitle3: 'Beijing R&D Center',
    contentTitle4: 'Shenyang R&D Center',
    contentTitle5: 'Address',
    contentTitle6: 'Business Cooperation',
    contentTitle7: 'Phone',
    contentTitle8:"Recruiting",
    contentTextp1:'The company was founded in June 2021. The founders and co-founders come from global leading companies of medical imaging equipment, such as GE, Philips, Canon and Siemens. ',
    contentTextp2:'The team, seizing the opportunity of photon counting technology commercialization, is dedicated to achieving technological breakthroughs in high-end imaging equipment. ',
    contentTextp3:'Through the technical innovation and business model innovation, the team aims to provide super products to healthcare institutions and super technical solutions to imaging equipment companies, and become the lead in healthcare industry.',
    contentText2: 'Beijing Photon Counting Medical Technology Ltd. was founded in Feb. 2024. The company is the holding company of Beijing Photon Counting Technology Ltd., focusing on the cooperation with the players in supply chain, and commercialization of technologies developed by Beijing Photon Counting Technology Ltd.',
    contentText4: 'The Beijing office is located in the Digital Health Industrial Park, Economic Development Zone, Daxing District, focusing on the development of photon counting detector key technologies, imaging algorithms, system design and integration, etc. The center has an area of 1500m², which meets the space requiement of mechanical and electrical experiments, system prototyping, integration, verification and validation, initial production of new developed CT system. The center serves as the headquarter of the company.',

    contentText5: 'The Shenyang R&D center leverages the geographical and talent advantages of the northeastern region of China, focusing on the development of key components, host software, user interface and user experience design. The branch is positioned as the Center of Excellent for CT software and key components.',
    contentText6: 'Room 101, Building 2, No.18 Keyuan Road, Economic Development Zone, Daxing District, Beijing',
    contentText7: 'email@photon-counting.cn',
    contentText8: '86-10-67809866',
    contentText9:"zhongyishan.zhang@photon-counting.cn",
  },
  product: {
    bannerText1: "Technologies & Solutions",
    navtitle: 'Technologies & Solutions',
    navtext: 'CT',
    nav1title1: 'Single X-Ray Sources Photon-counting CT',
    nav1title2: 'Multi X-Ray Sources Ultra High-end',
    nav1title2one:'Photon-counting CT',
    nav1title4: 'Large Aperture CT',
    nav1title3: 'Key components',
    nav1text1: 'CT with 40~80mm Z-direction coverage and 0.25~0.27 rot/s, can easily meet clinical application of conventional  and cardiac spectral.',
    nav1text2: 'CT with 40~80mm Z-direction coverage, 0.11~0.25 rot/s(equivalent speed) and 20ms temporal resolution, can easily achieve 4D scan in one beat.',
    nav1text4: 'Provide over 100cm large aperture CT system, could be used for intraoperative, radiotherapy simulation positioning, dynamic planning and adjustment during radiotherapy, and other applications.',
    nav1text3: 'CT software, hardware platform, control system, detector and other components.',
  },
  new: {
    title1: 'News',
    title2: 'Industry News',
    date1: '2021.06',
    date2: '2022.07',
    date3: '2022.10',
    date4: '2023.02',
    date5: '2023.12',
    date6:'2024.01',
    contentTitle1: 'Company Established',
    contentTitle2: 'Beijing R&D Center',
    contentTitle3: 'National Key Research and Development Program of China 14th Five-Year Plan',
    contentTitle4: 'Shenyang R&D Center',
    contentTitle5: 'Beijing Site Relocation',
    contentTitle6:'Recognized as',
    contentTitle7:'a National High-tech Enterprise',
    contentText1: 'Nearly 20 partners as the co-founder initiated and established Beijing Photon Counting Technology Ltd.',
    contentText2: 'Beijing R&D Center was officially established, focusing on system development, electronics and electrical engineering, photon-counting CT detector technology, and key algorithm design.',
    contentText3: "After opening bidding for selecting the best candidates, China’s Ministry of Science and Technology formally approved The Photon-Counting Spectral CT Development Project (Project Code: 2021YFC2401700) as one of the National Key Research and Development Programs in Bio-medical of China 14th Five-Year Plan.",
    contentText4: 'Shenyang R&D Center was officially established, focusing on mechanical design, software development, and user experience design.',
    contentText5: 'Beijing site relocated to Digital Health Industrial Park, Daxing. Established facility for system level prototyping and integration. The facility covers an area of 1,500m2 including 3 CT bays, capable of multiple product lines’R&D in parallel.',
    contentText6:'',
    listDate1:"Oct, 2023",
    listContentA1:"NAEOTOM Alpha photon counting CT system of Siemens healthcarers has got FDA approval, ",
    listContentA2:'并于2023年10月17日西门子取得首个光子计数的NMPA注册证。',
    listDate2:"April, 2023",
    listContent1:"FDA said this was the first major technological innovation for CT in more than 10 years, that had been approved by the FDA. In 2022, Siemens obtained the license for innovative product approval channels in China, and Siemens obtained the first NMPA registration certificate for photon counting on October 17, 2023.",
    listContent2:' 使用2022年12月批准的第一个日本生产的PCCT系统，佳能医疗、NCC探索性肿瘤学研究与临床试验中心（EPOC）和NCC医院东院已开始联合研究和临床试验，',
    listContentA3:"Canon Medical started clinical research of 1st Japanese produced PCCT system in National Cancer Center Japan. The NCC hospital East reported study results at the 82nd Congress of Japan Radiological Society and the 79th Congress of Japanese Society of Radiological technology held at Pacifico Yokohama from April 13 through April 16.",
    listContent3:'为了进一步加速PCCT的全球研究，',
    listContentA4:"佳能医疗系统株式会社分别于2023年11月14日和11月1日与RadboudUMC和广岛大学签订了协议，共同开展PCCT的实际应用临床研究。",
    listDate3:"May, 2021",
    listContentA5:'GE Healthcare announced Friday it is acquiring Swedish start-up Prismatic Sensors AB, bolstering its key medical imaging business. Prismatic Sensors AB was founded in 2012 as a spin-off from KTH Royal Institute of Technology in Stockholm, Sweden. It makes deep silicon detectors for CT imaging.',
    listDate4:"2020年11月",
    listContentA6:"光子计数能谱CT入选科技部十四五“诊断装备与生物医用材料”重点专项2021年度“揭榜挂帅”榜单，拟资助金额不低于6000万元人民币。其目标为针对临床上对更安全、更精准的CT影像需求问题，研发光子计数能谱CT，并开展应用。要求不低于64排，转速200RPM，覆盖40MM，空间分辨率30LP/CM，4个能带。"
  },
  footer: {
    address: 'Address: Room 101, Building 2, No.18 Keyuan Road, Economic Development Zone, Daxing District, Beijing',
    servicePhone: 'Tel',
    email: 'MailBox',
    icon: 'Copyright',
    icon2: '2022 - 2024',
    company1:"Beijing Photon-counting Technology Ltd.",
    company2:"Beijing Photon-counting Medical Technology Ltd.",
    ipcNumber:"",
    icon3:"",
  }
}