export default {
  header: {
    nav1: '首页',
    nav2: '公司介绍',
    nav3: '新闻中心',
    nav4: '技术和解决方案',
    nav5: '联系我们'
  },
  home: {
    product: {
      title: '科技引领未来',
      titleDetail:"科技创造奇迹，让未来发展不可限量。上下游联动、共享资源和成果、分散压力和风险实现模式创新，光子计数X射线能谱成像技术、多源协同成像技术、人工智能技术相结合打造光子计数X射线能谱成像影像设备平台。",
    
      product1: '瞬时全身扫描',
      product2: '4D动态影像',
      product3: '清晰呈现细微结构',
      product4: '超低剂量',
      product5: '超高低对比度分辨',
      product6:"多能带成像",
      product1Detail: '多源协同数据采集使扫描时间数倍加速，在3秒之内完成全身CT扫描，使得肺灌注甚至全身灌注成为可能。',
      product2Detail: '多源协同数据采集使时间分辨率数倍提高，70ms整扇区扫描，17ms时间分辨率使得4D动态序列成像成为现实。',
      product3Detail: '数倍于传统探测器的通道密度使得空间分辨率成倍提高，30lp/cm空间分辨率让亚毫米微细结构清晰可见。',
      product4Detail: '直接转换光子计数探测技术，0通道间隙和X射线光子直接转换使得几何效率和转换效率都显著提升，射线检查剂量显著降低。',
      product5Detail:" 低能光子在数据中的权重提升，使低对比度分辨能力得以显著提高。低keV虚拟单能图像更可清晰显示软组织。",
      product6Detail:"支持4个以上的能带(Energy Bins)使得在同一扫描中追踪多个造影剂成为可能，多能带也能提升物质鉴别的准确性。"
    },
    contactUs: {
      title: '富通康影在您身边',
      textContent: '北京富通康影科技有限公司成立于2021年6月。2022年7月开始正式运营，核心团队来自GE、飞利浦、佳能、西门子等全球领先医疗影像设备厂商。团队以“十四五”光子计数能谱CT国家重点研发项目为契机，致力于通过技术创新实现超高端影像设备的“弯道超车”和技术跨越，通过商业模式创新打造医学影像产品解决方案，为医疗机构和影像设备企业提供技术领先的产品和解决方案，致力于成为行业创新和技术引领者。',
      btn: '了解我们'
    },
    news: {
      title: '新闻',
      titleDetail:'1980年代以CERN为代表的核物理机构，针对高能粒子能谱分析开发出了单通道或线阵光子计数ASIC及探测器，1990年代CDTE/CZT探测器成为当时的热点，但CZT材料晶体缺陷限制了大尺寸探测器的应用，2000S，CERN推出MEDIPIX面阵探测器，具有256X256通道，并且可以拼接。GE HEALTHCARE、PHILIPS医疗和SIEMENS HEALTHINEERS都于2000年代开始研究光子计数探测器技术在CT中的应用，但直到2015年前后西门子在碲化镉光子计数探测器，GE HEALTHCARE在深硅光子计数探测器技术上才开始取得突破。',
      title1: '2023年10月',
      title2: '2021年5月',
      title5: "2023年04月",
      detail1a1:'西门子医疗NAEOTOM Alpha光子计数CT已经获得美国食品药品监督管理局（FDA）批准,',
      detail1: "FDA称这是近10年来“首个重要的CT影像设备的技术进步”。2022年在中国获得创新产品审批通道的许可，",
      detail1a2:'并于2023年10月17日西门子取得首个光子计数的NMPA注册证。',
      detail2: 'GE Healthcare周五宣布收购瑞典初创企业Prismatic Sensors AB，支持其关键医疗影像业务。Prismatic Sensors AB，支持其关键医疗影像业务。Prismatic Sensors AB成立于2012年，从位于瑞典斯德歌尔摩的KTH皇家技术学院独立出来。开发出了用于CT的硅基光子计数探测器。',
      title3: '2020年11月 ',
      detail5: '使用2022年12月批准的第一个日本生产的PCCT系统，佳能医疗、NCC探索性肿瘤学研究与临床试验中心（EPOC）和NCC医院东院已开始联合研究和临床试验，并于2023年4月13日至4月16日在横滨太平洋举行的第82届日本放射学会大会和第79届日本放射技术学会大会上报告研究结果。'
    }
  },
  contactUs: {
    bannerText1: '关于富通康影',
    bannerText2: '联系我们',
    title1: '品牌故事',
    title2: '研发中心',
    title3: '联系我们',
    contentTitle1: '北京富通康影科技有限公司',
    contentTitle2: '北京富通康影医疗科技有限公司',
    
    contentTitle3: '北京研发中心',
    contentTitle4: '沈阳研发中心',
    contentTitle5: '公司地址',
    contentTitle6: '商务合作',
    contentTitle7: '联系电话',
    contentTitle8:"招贤纳士",
    contentText9:"zhongyishan.zhang@photon-counting.cn",
    contentText1: '北京富通康影科技有限公司成立于2021年6月。2022年7月开始正式运营，核心团队来自GE、飞利浦、佳能、西门子等全球领先医疗影像设备厂商。团队以“十四五”光子计数能谱CT国家重点研发项目为契机，致力于通过技术创新实现超高端影像设备的“弯道超车”和技术跨越，通过商业模式创新打造医学影像产品解决方案，为医疗机构和影像设备企业提供技术领先的产品和解决方案，致力于成为行业创新和技术引领者。',
    contentText2: '北京富通康影医疗科技有限公司成立于2024年2月，是北京富通康影科技有限公司的控股公司，专注于在光子计数CT技术和系统解决方案基础上整合上下游产业链完成产品的产业化和商业化。',
    contentText4: '富通康影北京办公室目前位于北京市大兴区经济开发区数字健康产业园，聚焦光子计数CT探测器核心部件、成像算法及系统研发及项目执行，传统高、中、低端CT系统级解决方案。面积1500m²，可完成样机搭建及机械、电气、及整机测试，具备初步生产条件，作为公司总部。',
    contentText5: '沈阳研发中心，利用沈阳区位优势，医疗影像设备人才聚集优势，聚焦CT高压核心部件、用户界面软件、扫描床及用户体验设计，着力打造公司内部的CT软件及核心部件COE。',
    contentText6: '北京市大兴区经济开发区科苑路18号2幢一层101室',
    contentText7: 'email@photon-counting.cn',
    contentText8: '86-10-67809866'
    
  },
  product: {
    bannerText1: "技术和解决方案",
    navtitle: '技术和解决方案',
    navtext: 'CT',
    nav1title1: '单源光子计数CT',
    nav1title2: '多源超高端CT',
    nav1title3: '核心零部件',
    nav1title4: '大孔径CT',
    nav1text1: '40~80mm覆盖光子计数能谱CT系统，0.25~0.27s机架转速，满足常规及心脏能谱CT应用。',
    nav1text2: '40~80mm覆盖多源能谱CT系统，0.11~0.25s等效机架转速，20ms时间分辨率，自由心率4D扫描。',
    nav1text4: '100cm以上大孔径CT系统，可用于术中、放疗模拟定位、放疗中动态规划调整等场景。',
    nav1text3: 'CT软件、硬件平台、控制系统、探测器等核心部件。',
  },
  new: {
    title1: '新闻中心',
    title2: '行业新闻',
    date1: '2021.06',
    date2: '2022.07',
    date3: '2022.10',
    date4: '2023.02',
    date5: '2023.12',
    date6:'2024.01',
    contentTitle1: '富通康影成立',
    contentTitle2: '北京研发中心',
    contentTitle3: '十四∙五重点专项',
    contentTitle4: '沈阳研发中心',
    contentTitle5: '场地搬迁',
    contentTitle6:'获得国家高新技术企业认定',
    contentText1: '初创团队近20位合伙人共同发起成立。',
    contentText2: '北京研发中心正式设立聚焦系统、电子电气、光子计数CT探测器、核心算法设计。',
    contentText3: '科技部十四·五重点专项生物医药方向揭榜挂帅: 光子计数能谱CT研发项目立项批复 (2021YFC2401700)。',
    contentText4: '沈阳研发中心正式设立，聚焦机械设计、软件及用户体验设计。',
    contentText5: '迁址到大兴区数字健康产业园，完成样机集成调试场地准备工作，规划占地1500平米，3个调试间，可以满足多个产品并行研发要求。',
    contentText6:'',
    listDate1:"2023年10月",
    listContentA1:"西门子医疗NAEOTOM Alpha光子计数CT已经获得美国食品药品监督管理局（FDA）批准，",
    listContentA2:'并于2023年10月17日西门子取得首个光子计数的NMPA注册证。',
    listDate2:"2023年04月",
    listContent1:"FDA称这是近10年来“首个重要的CT影像设备的技术进步”。2022年在中国获得创新产品审批通道的许可，",
    listContent2:' 使用2022年12月批准的第一个日本生产的PCCT系统，佳能医疗、NCC探索性肿瘤学研究与临床试验中心（EPOC）和NCC医院东院已开始联合研究和临床试验，',
    listContentA3:"并于2023年4月13日至4月16日在横滨太平洋举行的第82届日本放射学会大会和第79届日本放射技术学会大会上报告研究结果。为了进一步加速PCCT的全球研究，",
    listContent3:'为了进一步加速PCCT的全球研究，',
    listContentA4:"佳能医疗系统株式会社分别于2023年11月14日和11月1日与RadboudUMC和广岛大学签订了协议，共同开展PCCT的实际应用临床研究。",
    listDate3:"2021年5月",
    listContentA5:'GE HEALTHCARE周五宣布收购瑞典初创企业PRISMATIC SENSORS AB，支持其关键医疗影像业务。PRISMATIC SENSORS AB，支持其关键医疗影像业务。PRISMATICSENSORSAB成立于2012年，从位于瑞典斯德歌尔摩的KTH皇家技术学院独立出来。开发出了用于CT的硅基光子计数探测器。',
    listDate4:"2020年11月",
    listContentA6:"光子计数能谱CT入选科技部十四五“诊断装备与生物医用材料”重点专项2021年度“揭榜挂帅”榜单，拟资助金额不低于6000万元人民币。其目标为针对临床上对更安全、更精准的CT影像需求问题，研发光子计数能谱CT，并开展应用。要求不低于64排，转速200RPM，覆盖40MM，空间分辨率30LP/CM，4个能带。"
  },
  footer: {
    address: '地址：北京市大兴区经济开发区科苑路18号2幢一层101室',
    servicePhone: '电话',
    email: '邮箱',
    icon: 'Copyright',
    icon2: '2022 - 2024',
    company1:"北京富通康影科技有限公司",
    company2:"北京富通康影医疗科技有限公司",
    icon3:" | ",
    ipcNumber:"京ICP备2024059744号"
  }
}