<template>
  <!-- 头部整体盒子 -->
  <div>
    <div id="header">
      <!-- pc端导航 -->
      <div class="header-nav container hidden-xs">
        <!-- 导航logo -->
        <div class="header-nav-logo">
          <img src="../assets/img/header_logo.png" alt="">
        </div>
        <!-- 导航内容 -->
        <ul class="header-nav-wrapper">
          <li v-for="(item, index) in navList" :key="index" :class="index == navIndex ? 'active' : ''"
            @click="navClick(index)">
            <router-link :to="item.path">
              {{ $t(item.name) }}
              <span v-if="item.children.length > 0" class="el-icon-arrow-down"></span>
              <i class="underline"></i>
            </router-link>
            <dl v-if="item.children.length > 0" class="nav-second-wrapper">
              <dt v-for="(i, n) in item.children" :key="n">
                <router-link :to="i.path">{{ $t(item.name) }}</router-link>
              </dt>
            </dl>
          </li>
          <li class="international-box">
            {{ language }}
            <span class="el-icon-arrow-down"></span>
            <dl class="nav-second-wrapper">
              <dt v-for="(langItem, n) in internationalList" :key="n" @click="changeLang(langItem)">
                {{ langItem.title }}
              </dt>
            </dl>
          </li>
        </ul>
      </div>
      <!-- 移动端导航 -->
      <div class="header-nav-m visible-xs">
        <div class="header-nav-m-logo">
          <img src="../assets/img/header_logo.png" alt="">
        </div>
        <!-- 导航内容 -->
        <el-col>
          <el-menu class="el-menu-vertical" @open="handleOpen" @close="handleClose" background-color="#474747"
            text-color="#fff">

            <el-submenu index="1">
              <template v-slot:title>
                <span class="nav-title text-center">{{ $t(navList[navIndex].name) }}</span>
              </template>
              <el-menu-item v-for="(item, index) in navList" :key="`1-${index}`" @click="navitemClick(index, item)">{{
            $t(item.name) }}
              </el-menu-item>
              <el-submenu index="2">
                <template v-slot:title>
                  <span class="nav-two-title">{{ language }}</span>
                </template>
                <el-menu-item v-for="(langItem, langIndex) in internationalList" :key="`1-${langIndex + langIndex}`"
                  @click="changeLang(langItem)">{{ langItem.title }}</el-menu-item>
              </el-submenu>
            </el-submenu>
          </el-menu>
        </el-col>
      </div>
    </div>

    <div class="header-position-box hidden-xs"></div>
    <div class="header-m-position-box visible-xs"></div>
  </div>


</template>
<script>
export default {
  name: "p-header",
  data () {
    return {
      internationalList: [
        {
          title: 'China-中文',
          lang: 'zh'
        },
        {
          title: 'Global-English',
          lang: 'en'
        }
      ],
      navList: [
        {
          name: 'header.nav1',
          path: "/",
          children: []
        },
        {
          name: 'header.nav2',
          path: "/about",
          children: []
        },
        {
          name: 'header.nav3',
          path: "/news",
          children: []
        },
        {
          name: 'header.nav4',
          path: "/product",
          children: []
        },
        {
          name: 'header.nav5',
          path: "/contactus",
          children: []
        }
      ]
    };
  },
  computed: {
    language () {
      return this.$store.getters.language == 'zh' ? 'China-中文' : 'Global-English'
    },
    navIndex () {
      const index = this.$store.getters.navIndex ? this.$store.getters.navIndex : 0
      return index
    }
  },
  methods: {
    navitemClick (index, item) {
      const { path } = item
      if (this.navIndex != index) {
        this.$store.commit('SET_NAVINDEX', index);
        this.$router.push(path)
      }
    },
    handleOpen () {
    },
    handleClose () {
    },

    navClick (index) {
      this.$store.commit('SET_NAVINDEX', index);
    },
    changeLang (langItem) {
      console.log(process.env.VUE_APP_IS_LOCAL)
      if (process.env.VUE_APP_IS_LOCAL === "true") {
        const { lang, title } = langItem
        this.$i18n.locale = lang
        this.internationalFlag = title
        this.$store.commit('SET_LANGUAGE', lang);
      } else {
        const { title } = langItem
        if (title === "Global-English") {
          window.location.href = 'https://global.photon-counting.cn/'

        } else {
          window.location.href = 'https://www.photon-counting.cn'
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
#header .header-nav .header-nav-wrapper {
  .international-box {
    margin-left: 30px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    list-style: none;

    &:hover span {
      transform: rotate(180deg);
    }

    span {
      font-size: 15px;
      transition: transform ease 0.5s;
    }

    dl {
      dt {
        font-size: 14px;

        &:hover {
          font-size: 16px;
        }
      }
    }
  }
}

/* 顶部 */
#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  background: #f4f4f4;
  transition: all ease 0.6s;
}

.header-position-box {
  height: 120px;
}

.header-m-position-box {
  height: 136px;
}

/* 导航栏 */
#header .header-nav {
  height: 120px;
}

/* 导航栏logo */
#header .header-nav .header-nav-logo {
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  position: relative;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}

/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 280px;
}

/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}

#header .header-nav .header-nav-wrapper {
  line-height: 120px;
  float: right;
  margin: 0;
}

/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper>li {
  float: left;
  margin: 0 15px;
  position: relative;
}

/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper>li>a {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0;
  position: relative;
  list-style: none;
}

/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper>li>a>i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #000;
}

/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper>li>a>span {
  font-size: 18px;
  transition: transform ease 0.5s;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper>li>a:hover {
  text-decoration: none;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper>li>a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper>li>a:hover span {
  transform: rotate(180deg);
}

/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper>li.active>a {
  text-decoration: none;
  border-bottom: 2px solid #000;
}

.nav-second-wrapper {
  padding: 20px 30px;

  dt {
    line-height: 1;
  }
}

/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper>li>dl {
  display: none;
  position: absolute;
  width: 115px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 1px 1px #ccc;
  background: #fff;
  border-radius: 4px;
  transition: transform ease 0.5s;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper>li>dl>dt {
  width: 100%;
  padding: 15px 0;
}

#header .header-nav .header-nav-wrapper>li>dl>dt>a {
  color: #666;
  font-size: 15px;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper>li>dl>dt>a:hover {
  text-decoration: none;
}

/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper>li:hover dl {
  display: block;
  overflow: hidden;
}

#header .header-nav .header-nav-wrapper>li>dl>dt:hover {
  cursor: pointer;
}

#header .header-nav .header-nav-wrapper>li>dl>dt:hover>a {
  color: #000;
  font-size: 16px;
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  #header .header-nav .header-nav-logo img {
    width: 200px;
  }

  #header .header-nav .header-nav-wrapper>li>a {
    font-size: 16px;
  }

  #header .header-nav .header-nav-wrapper .international-box {
    font-size: 16px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 993px) {
  .nav-title {
    display: inline-block;
    margin: 0 auto;
    font-size: 16px;
  }

  ::v-deep .el-submenu__title {
    display: flex;
  }

  .nav-wx-item {
    text-align: center;

    .el-submenu__title {
      padding-left: 0 45px !important;
    }
  }

  #header .header-nav-m {
    width: 100vw;
    overflow: hidden
  }

  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    position: relative;
  }

  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    font-size: 20px;
    height: auto;
    background: #474747;
    position: relative;
  }

  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
  }

  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #474747;
    z-index: 9999999;
  }

  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper>li {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
  }

  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper>li>a {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }

  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper>li>a>span {
    font-size: 10px;
  }
}
</style>
