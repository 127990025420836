<template>
  <div id="app">
    <p-header></p-header>
    <router-view />
    <p-footer></p-footer>
    <!-- <GoTop></GoTop> -->
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  width: 100vw;
}
</style>
