import Vue from 'vue'
import App from './App.vue'
/* 路由 */
import router from './router'
//vuex
import store from './store'
//国际化
import i18n from './lang'
//element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


/*element ui*/
Vue.use(ElementUI);

/* 重置样式 */
import './assets/css/reset.min.scss'


//动效

import 'wowjs/css/libs/animate.css'


/* 头部组件 */
import Header from './components/Header'
Vue.component(Header.name, Header)


/* 尾部组件 */
import Footer from './components/Footer'
Vue.component(Footer.name, Footer)

/* 回到顶部 */
import GoTop from './components/GoTop'
Vue.component(GoTop.name, GoTop)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
});

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')

