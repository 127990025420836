<template>
  <div id="footer" class="container-fluid">
    <div class="logo">
      <img src="../assets/img/logo_wihte.png" alt="">
    </div>
    <!-- <p class="title">photon-counting</p> -->
    <p class="address_tel_fax">
      <span>{{ $t('footer.address') }}
      </span>
    </p>
    <p class="email_wx">
      <span>{{ $t('footer.servicePhone') }}：+86-10-67809866</span>
      <span>{{ $t('footer.email') }}：email@photon-counting.cn
      </span>
    </p>
    <p class="copy">{{ $t('footer.icon') }} &copy; {{ $t('footer.icon2') }} <span class="icon3"
        v-if="language === 'zh'">{{ $t('footer.icon3')
        }}</span>
      <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank"><span> {{
        $t('footer.ipcNumber')
      }}</span></a>
      <span class="cybersecurity-box" v-if="language === 'zh'">
        <img src="../assets/img/cybersecurity.png" alt="">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011502037783" rel="noreferrer"
          target="_blank">京公网安备11011502037783</a>
      </span>


    </p>
    <p class="footer-company">{{ $t('footer.company1') }}</p>
    <p class="footer-company bottom-company">{{ $t('footer.company2') }}</p>


  </div>
</template>
<script>
export default {
  name: "p-footer",
  data () {
    return {};
  },
  computed: {
    language () {
      return this.$store.getters.language
    }
  },
}
</script>
<style scoped lang="scss">
#footer {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #474747;
  overflow: hidden;
  text-align: center;
}

.logo {
  display: flex;
  justify-content: center;
  height: 90px;
  margin: 30px auto;
}

.footer-company {
  font-size: 14px;
  color: #d3d3d3;
}

.icon3 {
  margin: 0 5px;
}

.cybersecurity-box {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;

  img {
    width: 16px;
  }

  a {
    color: #d3d3d3;
    font-size: 14px;
    margin-left: 5px;
  }
}

.bottom-company {
  margin-bottom: 20px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.address_tel_fax {
  color: #d3d3d3;
  font-size: 14px;
}

.email_wx {
  color: #d3d3d3;
  font-size: 14px;
  margin-top: 10px;

  span {
    margin-right: 10px;
  }
}

.copy {
  display: inline-flex;
  align-items: center;
  color: #d3d3d3;
  font-size: 14px;
  margin: 25px 0 10px;

  a {
    color: #d3d3d3;
  }

}

@media screen and (max-width: 993px) {
  .logo {
    height: 60px;
  }

  .title {
    font-size: 20px;
  }

  .address_tel_fax {
    font-size: 12px;
  }

  .email_wx {
    font-size: 12px;
  }

  .copy {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
</style>
